@import "vendors/normalize";
@import "fonts/fonts";
@import "base";

html {
  font-size: 62.5%;
}

body {
  font: 400 1.4rem/1.5 "Avenir Next", Arial, helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

a,
a:link,
a:visited {
  color: $blue;
  cursor: pointer;
}

input {
  line-height: 1.5;
}

h1 {
  @include headingH1;
}

h2 {
  @include headingH2;
}

// loading spinner
.loading {
  @include loading;
  border: 1ps solid $grey;
}

.mh100 {
  min-height: 100px;
}

.products {
  @include cf;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

// modal (style the default classes)
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 100%;
  min-width: 90vw;
  max-width: 100vw;
  height: auto !important;
  max-height: 100dvh;
  overflow: auto;
  @include fromTablet {
    min-width: calc($tablet - 4rem);
    max-width: calc($tablet);
  }
  @include fromDesktop {
    min-width: calc($desktop - 4rem);
    max-width: calc($desktop);
  }

  @include fromTablet {
    margin: 8vh auto auto;
    border-radius: $border-radius $border-radius 0 0;
  }
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role="tooltip"].popup-content {
  width: 200px;
  @include boxShadow;
}

// hotel calendar specific modal
[role="dialog"].hotelsModal-content {
  box-shadow: none;

  @include untilTablet {
  }

  @include fromTablet {
    margin: auto;
    border-radius: 0;
    width: 40%;
  }

  .popup-arrow {
    display: none;
  }
}

.popup-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.modal {
  border-radius: $border-radius $border-radius 0 0;
}

// react-calendar default style overrides
.react-calendar {
  margin: 0 auto 20px;
  border: none;
  font-family: Avenir Next;

  .react-calendar__month-view__days {
    @include fromTablet {
      box-shadow: 0 0 0 1px $grey-dark;
    }
  }

  .react-calendar__tile {
    position: relative;
    box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $grey-dark;
    min-height: 50px;
    font-size: $font-sm;

    @include fromTablet {
      box-shadow: inset 0 0 0 1px $grey-dark;
      min-height: 50px;
      font-size: $font-default;
    }

    .hotelsModal-content & {
      min-height: 30px;
      box-shadow: inset 0 0 0 0.5px $grey-dark;
    }

    abbr {
      position: absolute;
      left: 5px;
      top: 5px;
      font-weight: bold;
      @include fromTablet {
        top: 5px;
        left: auto;
        right: 5px;
      }

      .hotelsModal-content & {
        position: relative;
        left: auto;
        top: auto;
        right: auto;
      }
    }
  }
}

.react-calendar__tile--now {
  background: none;

  &:enabled:hover,
  &:enabled:focus {
    background: none;
  }
}

.react-calendar__navigation {
  justify-content: center;
  margin-bottom: 1rem;
  height: auto;

  button {
    &:enabled:hover,
    &:enabled:focus {
      background: none;
    }
  }
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  text-indent: -9999em;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 15px;
    height: 15px;
    width: 15px;
    border: 1px solid $grey;
    border-width: 3px 3px 0 0;
    transform: rotate(-135deg);
  }
}

.react-calendar__navigation__next-button {
  &::before {
    transform: rotate(45deg);
    left: auto;
    right: 15px;
  }
}

.react-calendar__navigation button:disabled,
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  visibility: hidden;
}

// hide these on mobile to give more space for the month name & year
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  .hotelsModal-content & {
    @include untilTablet {
      display: none;
    }
  }
}

.animate {
  animation: animationSlideFromRight 1s ease 0s 1 normal forwards;
}
@keyframes animationSlideFromRight {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}
.animate-reverse {
  animation: animationSlideFromLeft 1s ease 0s 1 normal forwards;
}
@keyframes animationSlideFromLeft {
  0% {
    transform: translateX(-250px);
  }

  100% {
    transform: translateX(0);
  }
}

.react-calendar__navigation__label {
  flex-grow: 0 !important;
}

.react-calendar__navigation__label__labelText {
  color: $blue-dark;
  font-weight: 900;
  font-size: $font-l;

  .hotelsModal-content & {
    font-weight: 600;
    font-size: $font-m;
  }
}

.react-calendar__tile--active {
  background: $blue-dark !important;

  &:enabled:hover,
  &:enabled:focus {
    background: $blue-dark;
  }
}

.react-calendar__month-view__days__day--weekend {
  color: $black;

  &.react-calendar__tile--active {
    color: $white;
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
  visibility: hidden;
}

body .react-tabs__tab-list {
  margin-bottom: 20px;
}

.react-tabs__tab {
  font-size: $font-m;
  color: $blue;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: $white;
}

// cookie message button
#onetrust-banner-sdk #onetrust-reject-all-handler {
  background: none !important;
  color: #1371c3 !important;
  border: none;
}
